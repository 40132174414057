import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { FormInstance } from 'antd/lib';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PopoverTooltip } from 'components/PopoverTooltip';

import { Styled } from './EditMetadataCard.styles';
import { isBlobImageSrc } from '../../../../../components/Image';
import { ImageData } from '../../../../../components/RichTextEditor/components/ImageModal';
import { ImageModalButton } from '../../../../../components/RichTextEditor/components/ImageModalButton';
import { ArticleDraft } from '../../../types/article';
import { CardTitle } from '../CardTitle';
import { LabelledLocalizedField } from '../fields/LabelledLocalizedField';
import { UnlocalizedFieldOutsideDefaultLangContainer } from '../fields/UnlocalizedFieldOutsideDefaultLangContainer';
import { useIsOutsideDefaultLanguage } from '../LanguageTabs/LanguageTabs.state';
import { ThumbnailImageFrame } from '../ThumbnailImageFrame';

export const EditMetadataCard = ({
  isThumbnailImageLoading,
  selectedLanguage,
  form,
}: {
  isThumbnailImageLoading?: boolean;
  selectedLanguage: string;
  form: FormInstance<ArticleDraft>;
}) => {
  const intl = useIntl();

  const [isOutsideDefaultLanguage] = useIsOutsideDefaultLanguage();

  const thumbnailSrc = useWatch(
    formData => formData.metadata?.thumbnail || formData.metadata?.thumbnailBlobPath,
    form
  );

  const handleThumbnailImageSelect = useCallback(
    (imageData: ImageData) => {
      if (isBlobImageSrc(imageData.src)) {
        form.setFieldValue(['metadata', 'thumbnail'], null);
        form.setFieldValue(['metadata', 'thumbnailBlobPath'], imageData.src);
        form.setFieldValue(['metadata', 'thumbnailBlobName'], imageData.imageName);
      } else {
        form.setFieldValue(['metadata', 'thumbnail'], imageData.src);
        form.setFieldValue(['metadata', 'thumbnailBlobPath'], null);
        form.setFieldValue(['metadata', 'thumbnailBlobName'], null);
      }
    },
    [form]
  );

  return (
    <>
      <CardTitle tooltip={<FormattedMessage id="articles.metadata.tooltip" />}>
        <FormattedMessage id="articles.metadata.title" />
      </CardTitle>

      <Styled.ContentContainer>
        <Styled.ThumbnailContainer>
          <ThumbnailImageFrame src={thumbnailSrc} loading={isThumbnailImageLoading} />

          <Styled.ThumbnailImageControlSection>
            <Typography.Text style={{ textAlign: 'left' }}>
              <FormattedMessage id="articles.metadata.thumbnail.title" />{' '}
              <PopoverTooltip>
                <FormattedMessage id="articles.metadata.thumbnail.tooltip" />
              </PopoverTooltip>
            </Typography.Text>

            {/* There needs to be an input related to these field, otherwise the
            form will not react to changes on their values  */}
            <Form.Item name={['metadata', 'thumbnail']} noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item name={['metadata', 'thumbnailBlobPath']} noStyle>
              <Input hidden />
            </Form.Item>
            <Form.Item name={['metadata', 'thumbnailBlobName']} noStyle>
              <Input hidden />
            </Form.Item>

            <UnlocalizedFieldOutsideDefaultLangContainer active={isOutsideDefaultLanguage}>
              <ImageModalButton
                disabled={isOutsideDefaultLanguage || !!isThumbnailImageLoading}
                label={
                  form.getFieldValue(['metadata', 'thumbnail']) ||
                  form.getFieldValue(['metadata', 'thumbnailBlobPath']) ? (
                    <FormattedMessage id="articles.thumbnail.select-another" />
                  ) : (
                    <FormattedMessage id="images.select" />
                  )
                }
                mode="select"
                onSelect={handleThumbnailImageSelect}
                imageType="thumbnail"
              />
            </UnlocalizedFieldOutsideDefaultLangContainer>

            {thumbnailSrc ? (
              <UnlocalizedFieldOutsideDefaultLangContainer active={isOutsideDefaultLanguage}>
                <Popconfirm
                  title={
                    <Styled.ThumbnailTitleContainer>
                      <FormattedMessage id="articles.thumbnail.delete.title" />
                    </Styled.ThumbnailTitleContainer>
                  }
                  cancelText={<FormattedMessage id="articles.thumbnail.delete.cancel" />}
                  okText={<FormattedMessage id="articles.thumbnail.delete.confirm" />}
                  onConfirm={() => {
                    form.setFieldValue(['metadata', 'thumbnailBlobPath'], null);
                    form.setFieldValue(['metadata', 'thumbnail'], null);
                  }}
                >
                  <Button disabled={isOutsideDefaultLanguage} icon={<DeleteOutlined />}>
                    <>
                      <FormattedMessage id="general.remove" />
                    </>
                  </Button>
                </Popconfirm>
              </UnlocalizedFieldOutsideDefaultLangContainer>
            ) : null}
          </Styled.ThumbnailImageControlSection>
        </Styled.ThumbnailContainer>

        <Styled.TextFieldsContainer>
          <LabelledLocalizedField
            language={selectedLanguage}
            label={intl.formatMessage({ id: 'articles.metadata.title-field.label' })}
            name={['metadata', 'title']}
            required
            rules={[
              {
                required: true,
                message: <FormattedMessage id="articles.metadata.title-field.required" />,
              },
            ]}
            tooltip={<FormattedMessage id="articles.metadata.title-field.tooltip" />}
          >
            <Input />
          </LabelledLocalizedField>

          {/* <Form.Item name={['metadata', 'tags']}>
            <Styled.TagsSelect />
          </Form.Item> */}

          <Styled.Description
            language={selectedLanguage}
            label={intl.formatMessage({ id: 'articles.metadata.description-field.label' })}
            name={['metadata', 'description']}
            tooltip={<FormattedMessage id="articles.metadata.description-field.tooltip" />}
          >
            <Input.TextArea />
          </Styled.Description>
        </Styled.TextFieldsContainer>
      </Styled.ContentContainer>
    </>
  );
};
