import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { format } from 'date-fns';
import { Observer } from 'mobx-react';
import { useContext, FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { DEFAULT_DATE_FORMAT, TIME_FORMAT_CLEAN } from 'constants/dateFormat';
import { CURRENT_ENV_TYPE, ENV } from 'constants/env';
import { ROLE_MENUITEMS } from 'constants/permissions';
import RootStoreContext from 'context/RootStoreContext';
import { CurrentPartnerStatus } from 'modules/PartnerStatus/stores/PartnerStatusViewStore';

import styles from './DeploymentButton.module.css';

interface Props {
  currentPartnerStatus: CurrentPartnerStatus | null;
}

const DeploymentButton: FunctionComponent<Props> = ({ currentPartnerStatus }) => {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { userPermissionsStore, partnerStatusStore } = useContext(RootStoreContext);

  return (
    <Observer>
      {() => {
        const {
          latestSuccessfullDeploymentDate,
          isLoading,
          isTriggeringPipeline,
          isLatestPipelinePending,
          triggerPipeline,
        } = partnerStatusStore;
        const canMakeMergeRequests =
          (userPermissionsStore.isAuthorizedToEditPartner ||
            userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.CONFIG_ADMIN)) &&
          CURRENT_ENV_TYPE !== ENV.DEMO;
        const isDisabled =
          !currentPartnerStatus?.changes.length ||
          isLoading ||
          isTriggeringPipeline ||
          isLatestPipelinePending;

        if (!canMakeMergeRequests) {
          return null;
        }

        return (
          <div className={styles.container} data-testid="deployment-button">
            {latestSuccessfullDeploymentDate && (
              <FormattedMessage
                id="partner-status.last-time-deployed"
                values={{
                  lastTimeDeployed: format(
                    new Date(latestSuccessfullDeploymentDate),
                    `${DEFAULT_DATE_FORMAT} ${TIME_FORMAT_CLEAN}`
                  ),
                }}
              />
            )}
            <div className={styles.buttonWrapper}>
              {!isDisabled && (
                <Tooltip
                  title={formatMessage({
                    id: 'partner-status.send-to-test-environment.description',
                  })}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              )}
              <Tooltip
                title={
                  isDisabled
                    ? formatMessage({ id: 'partner-status.no-new-changes-to-send' })
                    : undefined
                }
              >
                <Button
                  shape="round"
                  type="primary"
                  onClick={triggerPipeline}
                  disabled={isDisabled}
                  className={styles.button}
                  loading={isTriggeringPipeline}
                >
                  <FormattedMessage id="partner-status.send-to-test-environment" />
                </Button>
              </Tooltip>
            </div>
          </div>
        );
      }}
    </Observer>
  );
};

export default DeploymentButton;
