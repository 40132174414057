import { convertFromHTML, convertToHTML } from 'draft-convert';
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentBlock,
  RawDraftContentState,
} from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import React from 'react';

export function htmlToDraft(html: string) {
  return convertToRaw(
    convertFromHTML({
      // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
      htmlToEntity: (nodeName: string, node: HTMLElement, createEntity: Function) => {
        if (nodeName === 'a') {
          return createEntity('LINK', 'MUTABLE', { url: (node as HTMLAnchorElement).href });
        }

        if (nodeName === 'img') {
          return createEntity('IMAGE', 'IMMUTABLE', {
            src: (node as HTMLImageElement).src,
          });
        }

        return null;
      },
      htmlToBlock: (nodeName: string) => {
        if (nodeName === 'img') {
          return 'atomic';
        }

        return '';
      },
    })(html)
  );
}

export function draftToHtml(raw: RawDraftContentState) {
  return raw
    ? convertToHTML({
        blockToHTML: block => {
          if (block.type === 'atomic') {
            return {
              start: '',
              end: '',
            };
          }

          return null;
        },

        entityToHTML: (entity, originalText) => {
          if (entity.type === 'LINK') {
            return <a href={entity.data.url}>{originalText}</a>;
          }

          if (entity.type === 'IMAGE') {
            return <img src={entity.data.src} alt={entity.data.alt} />;
          }

          return originalText;
        },
      })(convertFromRaw(raw))
    : '';
}

export const convertMdToDraft = (md: string) =>
  rawContentStateImageEntityFixer(
    markdownToDraft(md, {
      blockEntities: {
        image: function (item) {
          return {
            type: 'IMAGE',
            mutability: 'MUTABLE',
            data: {
              src: item?.src,
              alt: item?.alt,
              title: item?.title,
            },
          };
        },
      },
    })
  );

export const convertDraftToMd = (raw: RawDraftContentState) =>
  markdownImageEntityContentReplacer(
    draftToMarkdown(raw, {
      entityItems: {
        IMAGE: {
          // Should be Entity type, but for some reason it lacks data property definition.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          open: (block?: any) => {
            const alt = block?.data?.alt || '';
            const title = block?.text ? ` "${block.text}"` : '';

            return `![${alt}](${block.data.src}${title})`;
          },
          close: () => '',
        },
      },
    })
  );

/**
 * Converts Draft.js raw content state produced by converter tool
 * so that blocks holding image entities have actually some content
 * that is replaced by an entity. If they don't, they are simply ignored
 * by Draft.js.
 *
 * @param rawContentState RawDraftContentState
 * @returns RawDraftContentState
 */
const rawContentStateImageEntityFixer = (
  rawContentState: RawDraftContentState
): RawDraftContentState => {
  const { blocks, entityMap } = rawContentState;
  const newBlockMap = blocks.reduce<RawDraftContentBlock[]>((accumulator, current) => {
    const entitiesCount = current.entityRanges.length;

    if (entitiesCount) {
      for (let i = 0; i < entitiesCount; i += 1) {
        const entityMeta = current.entityRanges[i];
        const entityKey = entityMeta.key;
        const entityData = entityMap[entityKey];

        if (entityData && entityData.type === 'IMAGE') {
          const newEntityMeta = {
            ...entityMeta,
            length: 1,
          };
          current.text = [
            current.text.slice(0, entityMeta.offset),
            '📷',
            current.text.slice(entityMeta.offset),
          ].join('');
          current.entityRanges[i] = newEntityMeta;
        }
      }
    }

    accumulator.push(current);
    return accumulator;
  }, []);

  return { ...rawContentState, blocks: newBlockMap };
};

/**
 * Removes additional content (unicode camera emoji) added by
 * customRawContentStateConverterImageEntityFix
 *
 * @param markdown Markdown formatted text
 * @returns Markdown formatted text
 */
const markdownImageEntityContentReplacer = (markdown: string) => {
  return markdown.replace(/(!\[[^\]]*\]\(.*?\s*(?:"(?:.*[^"])")?\s*\))(📷)/g, '$1');
};

export interface EntityPlacementData {
  blockKey: string;
  anchor: number;
  focus: number;
}

/**
 * Returns set of Entity "coordinates" inside a block.
 *
 * @param entityKey entity key
 * @param editorState EditorState
 * @returns EntityPlacementData
 */
export function getEntityPlacementDataByKey(
  entityKey: string,
  editorState: EditorState
): EntityPlacementData | undefined {
  const content = editorState.getCurrentContent();
  const blocks = content.getBlocksAsArray();
  let entityPlacementData: EntityPlacementData | undefined = undefined;

  for (let i = 0; i < blocks.length; i += 1) {
    const block = blocks[i];
    block.findEntityRanges(
      character => {
        if (character.getEntity() === entityKey) {
          entityPlacementData = {
            blockKey: block.getKey(),
            anchor: 0,
            focus: 0,
          };
          return true;
        }
        return false;
      },
      (anchor, focus) => {
        if (entityPlacementData) {
          entityPlacementData = { ...entityPlacementData, anchor, focus };
        }
      }
    );
    if (entityPlacementData) {
      break;
    }
  }

  return entityPlacementData;
}

/**
 * Strips file name from extension.
 * Unfortunately API sends file name with extension,
 * while what we need most is image id that allows us
 * to fetch the image data. This id is simply file name
 * without extension.
 *
 * @param fileName string
 * @returns string
 */
export function getImageIdFromFileName(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, '');
}

export const BLOB_URL_REGEXP = /!\[.*?]\((blob:[^\s)]+)\)/m;

/**
 * Checks if any of text contents contains blob urls.
 *
 * @param texts Texts
 * @returns boolean
 */
export function textsHaveBlobUrls(texts: { [key: string]: string }) {
  return Object.values(texts).some(textContent => BLOB_URL_REGEXP.test(textContent));
}
