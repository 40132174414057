import { Typography, Popconfirm, Card, List, Button } from 'antd';
import { Observer } from 'mobx-react';
import { useContext, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { CURRENT_ENV_TYPE, ENV } from 'constants/env';
import { ROLE_MENUITEMS } from 'constants/permissions';
import RootStoreContext from 'context/RootStoreContext';
import { CurrentPartnerStatus } from 'modules/PartnerStatus/stores/PartnerStatusViewStore';
import { markdownToInlineHtml } from 'utils/textUtils';
import { useLongDateTimeFormat } from 'utils/useDateFormat';

import styles from './PartnerStatusChanges.module.css';
import CommitStatusTag from '../CommitStatusTag';

interface Props {
  currentPartnerStatus: CurrentPartnerStatus | null;
  onCancelMergeRequest: () => void;
  onEditMergeRequest: () => void;
}

const MAX_CHANGES_PER_PAGE = 10;

const PartnerStatusChanges: FunctionComponent<Props> = ({
  currentPartnerStatus,
  onCancelMergeRequest,
  onEditMergeRequest,
}) => {
  const { mergeRequestStore, partnerStatusStore, userPermissionsStore } =
    useContext(RootStoreContext);

  const { format } = useLongDateTimeFormat();

  return (
    <Observer>
      {() => {
        const canMakeMergeRequests =
          (userPermissionsStore.isAuthorizedToEditPartner ||
            userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.CONFIG_ADMIN)) &&
          CURRENT_ENV_TYPE !== ENV.DEMO &&
          partnerStatusStore.isMergeRequestPending;
        const isPaginationShown =
          currentPartnerStatus?.changes &&
          currentPartnerStatus?.changes.length > MAX_CHANGES_PER_PAGE;

        return (
          <>
            {canMakeMergeRequests && (
              <>
                <p>
                  <FormattedMessage id="partner-status.pending-merge-request-description" />
                </p>
                <div className={styles.pendingMergeRequestHeading}>
                  <Typography.Title level={4} className={styles.changesTitle}>
                    <FormattedMessage id="general.description" />
                  </Typography.Title>
                  <div>
                    <Popconfirm
                      title={<FormattedMessage id="partner-status.sure-to-cancel-merge-request" />}
                      cancelText={<FormattedMessage id="general.cancel" />}
                      onConfirm={onCancelMergeRequest}
                    >
                      <Button
                        shape="round"
                        className={styles.cancelBtn}
                        disabled={mergeRequestStore.isLoading}
                        loading={mergeRequestStore.isLoading}
                      >
                        <FormattedMessage id="general.cancel" />
                      </Button>
                    </Popconfirm>
                    <Button
                      shape="round"
                      type="primary"
                      onClick={onEditMergeRequest}
                      disabled={mergeRequestStore.isLoading}
                    >
                      <FormattedMessage id="partner-status.edit-request" />
                    </Button>
                  </div>
                </div>
                <Card size="small" className={styles.description}>
                  <Typography.Paragraph>{currentPartnerStatus?.description}</Typography.Paragraph>
                </Card>
              </>
            )}
            <Typography.Title level={4}>
              <FormattedMessage id="partner-status.changes" />
            </Typography.Title>
            <List
              dataSource={currentPartnerStatus?.changes}
              loading={partnerStatusStore.isLoading || mergeRequestStore.isLoading}
              pagination={
                isPaginationShown
                  ? { simple: true, defaultPageSize: MAX_CHANGES_PER_PAGE }
                  : undefined
              }
              renderItem={change => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <div
                        dangerouslySetInnerHTML={{ __html: markdownToInlineHtml(change.message) }}
                      />
                    }
                    description={`${format(new Date(change.when))} | ${change.author}`}
                  />
                  {change.status && (
                    <div>
                      <CommitStatusTag status={change.status} />
                    </div>
                  )}
                </List.Item>
              )}
            />
          </>
        );
      }}
    </Observer>
  );
};

export default PartnerStatusChanges;
