import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { Button, Typography, Table, Popconfirm, Input, notification } from 'antd';
import { ColumnsType, SortOrder } from 'antd/lib/table/interface';
import classNames from 'classnames';
import { format } from 'date-fns';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import React, { ChangeEvent, Component, ContextType, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import withRootStoreProp, { RootStoreProps } from 'components/HOC/withRootStoreProp';
import PageHeader from 'components/PageHeader';
import { DEFAULT_DATE_FORMAT, TIME_FORMAT_CLEAN } from 'constants/dateFormat';
import RootStoreContext from 'context/RootStoreContext';
import { sortWithLocale } from 'utils/textUtils';

import styles from './Code24TestsList.module.css';
import CreateCode24TestModal from './components/CreateCode24TestModal';
import Code24TestsStore, { Code24Test } from './stores/Code24TestsStore';

/**
 * @notExported
 */
interface Code24TestsListProps extends RouteComponentProps, WrappedComponentProps, RootStoreProps {}

@observer
class Code24TestsList extends Component<Code24TestsListProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;
  code24TestsStore: Code24TestsStore;

  constructor(props: Code24TestsListProps) {
    super(props);

    this.code24TestsStore = new Code24TestsStore(props.rootStore);
  }

  componentDidMount() {
    this.code24TestsStore.fetchCode24Tests();
  }

  componentWillUnmount() {
    this.code24TestsStore.dispose();
  }

  handleSubmit = (values: { interviewId: string }) => {
    const { intl } = this.props;
    return this.code24TestsStore.handleCreateCode24Test(values.interviewId).then(res => {
      if (res) {
        notification.success({
          placement: 'top',
          message: intl.formatMessage({ id: 'test.new-test-created-message' }),
        });
      }
    });
  };

  get columns(): ColumnsType<Code24Test> {
    return [
      {
        title: <FormattedMessage id="test.table.column.name" />,
        dataIndex: 'id',
        width: 400,
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: Code24Test, b: Code24Test) => sortWithLocale(a, b, 'title'),
        render: (_: string, record: Code24Test) => {
          return (
            <>
              {record.success === false && (
                <ExclamationCircleFilled
                  data-testid="failed-icon"
                  className={styles.statusFailedIcon}
                />
              )}{' '}
              {record.id}
            </>
          );
        },
      },
      {
        title: <FormattedMessage id="test.table.column.status" />,
        dataIndex: 'success',
        width: 180,
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: Code24Test, b: Code24Test) => sortWithLocale(a, b, 'success'),
        render: (_: string, record: Code24Test) => {
          if (record.success) {
            return (
              <span>
                <CheckCircleFilled className={classNames(styles.booleanIcon, styles.trueIcon)} />
                <FormattedMessage id="test.status.passed" />
              </span>
            );
          }
          if (record.success === false) {
            return (
              <span>
                <CloseCircleFilled className={classNames(styles.booleanIcon, styles.falseIcon)} />
                <FormattedMessage id="test.status.failed" />
              </span>
            );
          }

          return null;
        },
      },
      {
        title: <FormattedMessage id="test.table.column.message" />,
        sortDirections: ['descend', 'ascend'] as SortOrder[],
        defaultSortOrder: 'ascend' as SortOrder,
        sorter: (a: Code24Test, b: Code24Test) => sortWithLocale(a, b, 'errorMessage'),
        dataIndex: 'errorMessage',
      },
      {
        title: <FormattedMessage id="general.actions" />,
        dataIndex: 'actions',
        width: 100,
        render: (_: string, record: Code24Test) => (
          <Popconfirm
            title={<FormattedMessage id="general.sure-to-delete" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onConfirm={() => this.code24TestsStore.handleDeleteCode24Test(record.id)}
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      },
    ];
  }

  handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.code24TestsStore.handleSearch(event.target.value.trim().toLowerCase());
  };

  private handleRunAllTests = async () => {
    this.code24TestsStore.createCode24TestsReport();
  };

  render() {
    const { filteredResults, isReportPending, lastReportDate } = this.code24TestsStore;
    const isLoading = this.code24TestsStore.isLoading(); // cannot be destructured
    const isDisabled = isLoading || isReportPending;

    const { intl } = this.props;
    return (
      <>
        <PageHeader
          className={styles.clearPageHeaderStyle}
          content={
            <Fragment>
              <Typography.Title level={2}>
                <FormattedMessage id="test.header" />
              </Typography.Title>
              <Typography.Paragraph>
                <FormattedMessage id="test.description" />
              </Typography.Paragraph>
            </Fragment>
          }
        />
        <div className={styles.headerActions}>
          <div className={styles.headerActions}>
            <Button
              shape="round"
              type="primary"
              data-testid="run-all-button"
              disabled={isDisabled}
              loading={isDisabled}
              className={styles.runTestsBtn}
              onClick={this.handleRunAllTests}
            >
              <FormattedMessage id="test.run-all-button" />
            </Button>
            {lastReportDate && (
              <div>
                <FormattedMessage
                  id="test.last-run"
                  values={{
                    lastRunTime: format(
                      new Date(lastReportDate),
                      `${DEFAULT_DATE_FORMAT} ${TIME_FORMAT_CLEAN}`
                    ),
                  }}
                />
              </div>
            )}
            {isReportPending && (
              <div>
                <FormattedMessage id="test.running.message" />
              </div>
            )}
          </div>
          <Input.Search
            placeholder={intl.formatMessage({
              id: 'test.search-placeholder',
            })}
            data-testid="search-input"
            onChange={this.handleSearchChange}
            disabled={isDisabled}
            className={styles.search}
          />
        </div>
        <Table
          columns={this.columns}
          dataSource={toJS(filteredResults)}
          className={styles.table}
          rowKey="id"
          loading={isDisabled}
        />
        <CreateCode24TestModal
          isSaving={isLoading}
          isDisabled={isReportPending}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

export default withRootStoreProp(injectIntl(Code24TestsList));
