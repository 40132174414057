import {
  DeleteOutlined,
  PlusCircleOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  EditOutlined,
  PicLeftOutlined,
  EyeOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import {
  Table,
  Button,
  Input,
  Popconfirm,
  Tag,
  Form,
  Switch,
  Typography,
  Tooltip,
  Alert,
} from 'antd';
import noop from 'lodash/noop';
import { observer } from 'mobx-react';
import { Component, Fragment, ContextType } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Highlighter from 'react-highlight-words';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import { AppBreadcrumbItem } from 'components/Breadcrumbs';
import DraggableRow from 'components/DraggableRow';
import LanguageSwitcher from 'components/LanguageSwitcher';
import PageHeader from 'components/PageHeader';
import PlusFloatingButton from 'components/PlusFloatingButton';
import { LANGS } from 'constants/enums';
import { EDIT_ACTIONS_FIELD } from 'constants/general';
import RootStoreContext from 'context/RootStoreContext';
import RootStore from 'stores/RootStore';
import { TranslatedText } from 'types/types';

import { ExitCategory } from './api/partnerCode24api';
import AddSectionForm from './components/AddSectionForm';
import AddStatement from './components/AddStatement';
import BlockIcon from './components/BlockIcon';
import DiagnosisCodes from './components/DiagnosisCodes';
import EditStatement from './components/EditStatement';
import ErrorTooltip from './components/ErrorTooltip';
import MetadataForm from './components/MetadataForm';
import SearchTerms from './components/SearchTerms';
import styles from './Condition.module.css';
import {
  CODE24_CATEGORIES,
  CODE24_MODEL_TYPES,
  EXIT_ATTRIBUTES_KEYS,
} from './constants/code24types';
import { Statement } from './models/Code24Model';
import ConditionViewStore, {
  ConditionListItem,
  ConditionListSection,
} from './stores/ConditionViewStore';

/**
 * @notExported
 */
interface ConditionProps extends RouteComponentProps<{ id: string }>, WrappedComponentProps {}

const dndProps = {
  backend: HTML5Backend,
};

@observer
class Condition extends Component<ConditionProps> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  conditionViewStore: ConditionViewStore;

  components = {
    body: {
      row: DraggableRow,
    },
  };

  private get group() {
    const { conditionStore, content24Store } = this.context;
    const { condition } = conditionStore;
    const { groupCategories } = content24Store;
    return groupCategories.get(condition.metadata.level1id) || '';
  }

  private get breadcrumbs() {
    const { conditionStore, content24Store } = this.context;
    const { condition } = conditionStore;
    const { groupCategories } = content24Store;
    const basePath: AppBreadcrumbItem[] = [
      {
        icon: <PicLeftOutlined />,
        text: <FormattedMessage id="main-navigation.content24" />,
      },
      {
        text: <FormattedMessage id="condition-list.header" />,
      },
    ];

    if (!condition.metadata.conditionId || !groupCategories.size) {
      return basePath;
    }

    return basePath.concat([
      {
        text: (
          <FormattedMessage
            id={this.group ? `condition-list.category-${this.group}` : 'general.unknown'}
          />
        ),
        link: this.group ? `/content24/questionnaires?category=${this.group}` : undefined,
      },
      {
        text: this.conditionViewStore.title,
      },
    ]);
  }

  columns = [
    {
      title: <FormattedMessage id="condition-edit.question-type-label" />,
      dataIndex: 'type',
      className: styles.typeColumn,
      render: (_: string, record: ConditionListItem) => this.renderType(record),
    },
    {
      title: <FormattedMessage id="condition-edit.id-label" />,
      dataIndex: 'id',
      className: styles.idColumn,
      render: (_: string, record: ConditionListItem) => this.renderId(record),
    },
    {
      title: <FormattedMessage id="condition-edit.condition-label" />,
      dataIndex: 'condition',
    },
    {
      title: <FormattedMessage id="condition-edit.exit-attribute-header" />,
      render: (_: string, record: ConditionListItem) => this.renderExitAttributes(record),
    },
    {
      title: <FormattedMessage id="general.content" />,
      render: (_: string, record: ConditionListItem) => this.renderContent(record),
    },
    {
      title: <FormattedMessage id="general.actions" />,
      dataIndex: EDIT_ACTIONS_FIELD,
      width: 100,
      render: (_: string, record: ConditionListItem) => this.renderActionButtons(record),
    },
  ];

  constructor(props: ConditionProps, context: RootStore) {
    super(props);
    this.conditionViewStore = new ConditionViewStore(context, props.intl.locale as LANGS);
  }

  componentDidMount() {
    const { conditionStore } = this.context;

    conditionStore.fetchAvailableSectionStatements();
  }

  getRowKey = (record: ConditionListItem) => record.id;

  renderType(record: ConditionListItem) {
    const errorMessages = ('errorMessages' in record && record.errorMessages) || [];
    const hasError = !!errorMessages.length;
    const hasChildren = 'children' in record;

    return (
      <Fragment>
        {hasError && <ErrorTooltip errors={errorMessages} />}
        {!hasError && !hasChildren && <span className={styles.spacer}></span>}
        <BlockIcon block={record} />
        {hasChildren ? (
          this.renderText(this.props.intl.formatMessage({ id: `condition.type-${record.type}` }))
        ) : (
          <span className={styles.blockType}>
            {this.renderText(
              this.props.intl.formatMessage({ id: `condition.type-${record.type}` })
            )}
          </span>
        )}
      </Fragment>
    );
  }

  renderId = (listItem: ConditionListItem) => {
    switch (listItem.type) {
      case CODE24_MODEL_TYPES.QUESTION:
        return this.renderText('questionId' in listItem ? listItem.questionId : '');
      case CODE24_MODEL_TYPES.FORMULA:
        return this.renderText('formulaId' in listItem ? listItem.formulaId : '');
      case CODE24_MODEL_TYPES.EXPORT_DATA:
        return this.renderText('destinationId' in listItem ? listItem.destinationId : '');
      case CODE24_MODEL_TYPES.IMPORT_DATA:
        return this.renderText('importSourceId' in listItem ? listItem.importSourceId : '');
      case CODE24_MODEL_TYPES.EXIT:
        return this.renderText('exitId' in listItem ? listItem.exitId : '');
      default:
        return null;
    }
  };

  renderContent = (listItem: ConditionListItem) => {
    const { locale } = this.props.intl;

    switch (listItem.type) {
      case CODE24_MODEL_TYPES.QUESTION:
      case CODE24_MODEL_TYPES.EXIT:
      case CODE24_MODEL_TYPES.CHECK:
        if ('patient' in listItem && listItem.patient && listItem.patient[locale]) {
          return this.renderText(listItem.patient[locale]);
        } else if ('patient4' in listItem && listItem.patient4 && listItem.patient4[locale]) {
          return this.renderText(listItem.patient4[locale]);
        }
        return null;
      case CODE24_MODEL_TYPES.GOTO:
      case CODE24_MODEL_TYPES.INCLUDE:
      case CODE24_MODEL_TYPES.CALL:
        return 'target' in listItem ? this.renderText(listItem.target) : null;
      case CODE24_MODEL_TYPES.GUIDANCE:
        return 'code' in listItem && listItem.code ? this.renderText(listItem.code) : null;
      default:
        return null;
    }
  };

  renderText = (text: string) =>
    this.conditionViewStore.searchTerm ? (
      <Highlighter
        highlightClassName={styles.highlightedText}
        searchWords={[this.conditionViewStore.searchTerm]}
        autoEscape
        textToHighlight={text}
      />
    ) : (
      text
    );

  renderExitAttributes = (listItem: ConditionListItem) => (
    <Fragment>
      {EXIT_ATTRIBUTES_KEYS.map((field: string) =>
        field in listItem && listItem[field]
          ? Array.isArray(listItem[field])
            ? listItem[field].map((value: string | number) =>
                this.renderExitAttribute(field, value)
              )
            : this.renderExitAttribute(field, listItem[field])
          : null
      )}
    </Fragment>
  );

  renderExitAttribute = (key: string, value: string | number) => {
    const exitGroupCategories: ExitCategory[] =
      this.context.content24Store.exitAttributesTranslations?.[key];
    const exitCategory: TranslatedText | undefined = exitGroupCategories?.[value];
    const translation: string | undefined = exitCategory?.[this.props.intl.locale];

    return (
      <Tag className={styles.exitAttributeTag} key={`${key}${value}`}>
        {this.renderText(translation || String(value))}
      </Tag>
    );
  };

  renderActionButtons = (listItem: ConditionListItem) => {
    const {
      partnersStore,
      content24Store: { canAddContent24, canEditContent24 },
    } = this.context;
    const isSectionItem = 'children' in listItem;
    const availableStatements =
      partnersStore.partnerCustomizations.get('CODE24_AVAILABLE_STATEMENTS') || [];
    const {
      isDisabledCondition,
      handleActiveStatement,
      handleNewStatementSection,
      handleDeleteStatement,
    } = this.conditionViewStore;
    const isAddNewStatementButtonVisible =
      isSectionItem &&
      !!availableStatements.filter(
        (value: CODE24_MODEL_TYPES) => value !== CODE24_MODEL_TYPES.SEARCH_TERM
      ).length &&
      canAddContent24;
    const isStatementItem = !isSectionItem;
    const isIntroStatement = listItem.type === CODE24_MODEL_TYPES.INTRO;

    if (!(canAddContent24 && canEditContent24) || isDisabledCondition) {
      return isSectionItem || isIntroStatement ? null : (
        <Button
          type="link"
          icon={<EyeOutlined />}
          onClick={() => handleActiveStatement(listItem as Statement)}
        />
      );
    }

    return (
      <Fragment>
        {isStatementItem && !isIntroStatement && canEditContent24 && (
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleActiveStatement(listItem as Statement)}
          />
        )}
        {isStatementItem && canEditContent24 && (
          <Popconfirm
            title={<FormattedMessage id="general.sure-to-delete" />}
            cancelText={<FormattedMessage id="general.cancel" />}
            onConfirm={() =>
              handleDeleteStatement(listItem as Statement, {
                successMessage: this.props.intl.formatMessage({
                  id: 'condition-edit.statement-deleted',
                }),
                errorMessage: this.props.intl.formatMessage({ id: 'general.error' }),
              })
            }
          >
            <Button type="link" icon={<DeleteOutlined />} />
          </Popconfirm>
        )}
        {isAddNewStatementButtonVisible && (
          <Button
            type="link"
            icon={<PlusCircleOutlined />}
            onClick={() => handleNewStatementSection(listItem as ConditionListSection)}
          />
        )}
      </Fragment>
    );
  };

  render() {
    const { content24Store } = this.context;
    const { intl } = this.props;
    const {
      activeLanguage,
      activeStatement,
      availableNewSections,
      conditionDefaultValues,
      conditionHasErrors,
      isCheckingForErrors,
      conditionIsHidden,
      conditionItemsList,
      conditionMetadata,
      expandedRowKeys,
      handleAddNewSection,
      handleAddStatement,
      handleClearActiveStatement,
      handleClearNewSection,
      handleClearNewStatementSection,
      handleConditionConversion,
      handleExpand,
      handleHiddenConditionSwitchChange,
      handleLanguageChange,
      handleMoveStatement,
      handleNewSection,
      handleSearchChange,
      handleUpdateActiveStatement,
      handleUpdateMetadata,
      isDisabledCondition,
      isLoading,
      isDefaultCondition,
      isDiagnosisCodesDisplayed,
      isSearchTermsDisplayed,
      newSection,
      newStatementSection,
      newStatementTypeOptions,
      title,
      conditionCategory,
      conditionGroupsSelectOptions,
      isUpdatingHiddenConditions,
    } = this.conditionViewStore;

    const originLanguagesOptions = content24Store.availableLanguages.map(lang => ({
      value: lang,
      label: (intl.messages[`general.language-${lang}`] || lang).toString(),
    }));
    const { canAddContent24, canEditContent24 } = content24Store;

    return (
      <Fragment>
        <PageHeader
          content={
            <Fragment>
              <div className={styles.headerTop}>
                <Typography.Title level={2} className={styles.title}>
                  <span>{title}</span>
                  {conditionHasErrors && (
                    <Tooltip
                      placement="right"
                      title={<FormattedMessage id="condition.validation-error" />}
                      className={styles.validationErrorTooltip}
                    >
                      <ExclamationCircleFilled className={styles.validationErrorIcon} />
                    </Tooltip>
                  )}
                  {!!conditionIsHidden && (
                    <Tag color="green" className={styles.hiddenTag}>
                      <FormattedMessage id="general.hidden" />
                    </Tag>
                  )}
                </Typography.Title>
                <div className={styles.headerActions}>
                  {isDefaultCondition && (
                    <Popconfirm
                      title={<FormattedMessage id="condition-edit.sure-to-convert" />}
                      cancelText={<FormattedMessage id="general.cancel" />}
                      onConfirm={handleConditionConversion({
                        successMessage: this.props.intl.formatMessage({
                          id: 'condition-edit.condition-successfully-converted',
                        }),
                        errorMessage: this.props.intl.formatMessage({
                          id: 'condition-edit.conversion-failed',
                        }),
                      })}
                      placement="bottom"
                      disabled={isLoading || isCheckingForErrors || conditionHasErrors}
                    >
                      <Tooltip
                        title={
                          <FormattedMessage id="condition-edit.conversion-disabled-information" />
                        }
                        //@ts-ignore bug in AntDesign types, zIndex not reported as a Tooltip prop
                        zIndex={conditionHasErrors ? 1 : -1}
                      >
                        <Button
                          shape="round"
                          type="primary"
                          disabled={isLoading || isCheckingForErrors || conditionHasErrors}
                          loading={isLoading}
                          className={styles.headerAction}
                        >
                          <FormattedMessage id="condition-edit.convert" />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  )}
                  <Button
                    shape="round"
                    type="primary"
                    disabled={isLoading}
                    loading={isLoading}
                    onClick={() =>
                      this.props.history.push(
                        `/content24/questionnaires/${this.props.match.params.id}/visualization`
                      )
                    }
                    className={styles.headerAction}
                  >
                    <FormattedMessage id="condition.visualization.trigger-btn" />
                  </Button>
                </div>
              </div>
              {this.group !== CODE24_CATEGORIES.LIBRARIES && (
                <Form>
                  <Form.Item
                    label={<FormattedMessage id="general.hidden" />}
                    className={styles.hiddenSwitch}
                  >
                    <Switch
                      checked={conditionIsHidden}
                      onChange={handleHiddenConditionSwitchChange}
                      disabled={
                        !canEditContent24 ||
                        isLoading ||
                        isUpdatingHiddenConditions ||
                        isDisabledCondition
                      }
                      loading={isLoading || isUpdatingHiddenConditions}
                    />
                  </Form.Item>
                </Form>
              )}
            </Fragment>
          }
          breadcrumbs={this.breadcrumbs}
          headerActions={
            <div className={styles.headerActions}>
              <LanguageSwitcher
                activeLanguage={activeLanguage}
                languages={originLanguagesOptions}
                onChange={handleLanguageChange}
                className={styles.headerAction}
                isDisabled={isLoading}
              />
              <Input.Search
                placeholder={this.props.intl.formatMessage({ id: 'general.search' })}
                onChange={handleSearchChange}
                className={styles.headerAction}
                disabled={isLoading}
              />
            </div>
          }
        />
        {isDefaultCondition && (
          <Alert
            message={this.props.intl.formatMessage({
              id: 'condition-edit.conversion-information',
            })}
            description={this.props.intl.formatMessage({
              id: 'condition-edit.conversion-warning',
            })}
            type="warning"
            showIcon
            className={styles.notification}
          />
        )}
        <MetadataForm
          data={conditionMetadata}
          onSubmit={handleUpdateMetadata({
            successMessage: this.props.intl.formatMessage({
              id: 'condition-edit.statement-updated',
            }),
            errorMessage: this.props.intl.formatMessage({ id: 'general.error' }),
          })}
          groupsSelectOptions={conditionGroupsSelectOptions}
          isLoading={isLoading}
          activeLanguage={activeLanguage}
          conditionCategory={conditionCategory}
          isDisabled={!canEditContent24 || isDisabledCondition}
        />
        {isDiagnosisCodesDisplayed && (
          // isDiagnosisCodesDisplayed checks if codes are available

          <DiagnosisCodes diagnosisCodes={conditionDefaultValues!.codes!} />
        )}
        {isSearchTermsDisplayed && (
          <div className={styles.searchTerms}>
            <SearchTerms conditionViewStore={this.conditionViewStore} />
          </div>
        )}
        <DndProvider {...dndProps}>
          <Table<ConditionListItem>
            columns={this.columns}
            dataSource={conditionItemsList}
            pagination={false}
            rowKey={this.getRowKey}
            className={styles.table}
            loading={isLoading}
            onExpand={handleExpand}
            expandIcon={({ expanded, onExpand, record }) =>
              'children' in record && record.children.length ? (
                <Button
                  type="link"
                  icon={expanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
                  onClick={e => onExpand(record, e)}
                />
              ) : null
            }
            expandedRowKeys={expandedRowKeys.slice()}
            components={this.components}
            onRow={(record, index) => ({
              index,
              moveRow: handleMoveStatement,
              dragSection: !('children' in record)
                ? conditionItemsList.find(section =>
                    section.children.find(({ id }) => id === record.id)
                  )?.id
                : 'nodrag',
              isDraggable:
                canEditContent24 &&
                !isDisabledCondition &&
                !('children' in record) &&
                record.type !== CODE24_MODEL_TYPES.INTRO,
              // Required by onRow return type
              onClick: noop,
            })}
          />
        </DndProvider>
        <AddStatement
          isVisible={!!newStatementSection}
          isSaving={false}
          onCancel={handleClearNewStatementSection}
          onSubmit={handleAddStatement({
            successMessage: this.props.intl.formatMessage({
              id: 'condition-edit.statement-created',
            }),
            errorMessage: this.props.intl.formatMessage({ id: 'general.error' }),
          })}
          statementTypeOptions={newStatementTypeOptions}
          activeLanguage={activeLanguage}
          availableLanguages={content24Store.availableLanguages}
          onLanguageChange={handleLanguageChange}
          isDisabled={!canEditContent24 || isDisabledCondition}
        />
        <EditStatement
          statement={activeStatement}
          onSubmit={handleUpdateActiveStatement({
            successMessage: this.props.intl.formatMessage({
              id: 'condition-edit.statement-updated',
            }),
            errorMessage: this.props.intl.formatMessage({ id: 'general.error' }),
          })}
          onCancel={handleClearActiveStatement}
          onLanguageChange={handleLanguageChange}
          activeLanguage={activeLanguage}
          availableLanguages={content24Store.availableLanguages}
          isDisabled={!canEditContent24 || isDisabledCondition}
          diagnosisCodes={conditionDefaultValues?.codes}
        />
        <AddSectionForm
          data={newSection}
          availableSections={availableNewSections}
          isSaving={isLoading}
          onSubmit={handleAddNewSection}
          onCancel={handleClearNewSection}
          isDisabled={!canAddContent24 || isDisabledCondition}
        />
        {canAddContent24 && !!availableNewSections.length && !isDisabledCondition && (
          <PlusFloatingButton onClick={handleNewSection} />
        )}
      </Fragment>
    );
  }
}

export default injectIntl(Condition);
