/* eslint-disable @typescript-eslint/no-unused-expressions */
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Popconfirm, Typography, Tooltip, Checkbox } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, ContextType, Fragment } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import withPractitionerRolesOptions, {
  PractitionerRolesOptionsProps,
} from 'components/HOC/withPractitionerRolesOptions';
import { ROLE_MENUITEMS } from 'constants/permissions';
import { Config } from 'constants/practitioner';
import {
  DEFAULT_ADMINISTRATIVE_ROLE,
  DEFAULT_PRACTITIONER_RECEIVING_ROLE,
  ROLES,
  ROLES_DESCRIPTION_KEY,
} from 'constants/roles';
import RootStoreContext from 'context/RootStoreContext';
import {
  displayCareUnitWithProviderName,
  getPartnerIdByCareUnitIdGivenAllCareUnits,
} from 'utils/role.utils';

import AddPractitionerRoles from './components/AddPractitionerRoles';
import EditPractitionerRole from './components/EditPractitionerRole';
import styles from './PractitionerRoles.module.css';
import { RoleInCareUnit } from '../stores/PractitionerRolesStore';

export interface PractitionerRoleDefinition {
  role?: ROLES;
  careUnitIds: string[];
  lockedFromAutoManagement?: boolean;
}

interface Props
  extends PractitionerRolesOptionsProps,
    WrappedComponentProps,
    RouteComponentProps<{ id: string }> {}

@observer
class PractitionerRoles extends Component<Props> {
  static contextType = RootStoreContext;
  declare context: ContextType<typeof RootStoreContext>;

  @observable newRoleDefinition?: PractitionerRoleDefinition;
  @observable activePractitionerRole?: RoleInCareUnit;

  async componentDidMount() {
    const { careUnitsStore, practitionerRolesStore, partnersStore } = this.context;

    await careUnitsStore.fetchAllCareUnits();
    await practitionerRolesStore.initializeRoles(this.props.match.params.id);
    await partnersStore.fetchPartnerConfiguration();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.context.practitionerRolesStore.initializeRoles(this.props.match.params.id);
    }
  }

  componentWillUnmount() {
    this.context.practitionerRolesStore.disposeRoles();
  }

  get columns(): ColumnsType<RoleInCareUnit> {
    const ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT = this.context.partnersStore.partnerConfig.get(
      Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
    );
    const mColumns: ColumnsType<RoleInCareUnit> = [
      {
        title: <FormattedMessage id="general.care-unit" />,
        dataIndex: 'careUnitName',
        width: 400,
      },
      {
        title: <FormattedMessage id="practitioner-roles-form.role-label" />,
        dataIndex: 'role',
        width: 200,
        render: (_, record) =>
          ROLES_DESCRIPTION_KEY[record.role]
            ? this.props.intl.formatMessage({
                id: ROLES_DESCRIPTION_KEY[record.role],
              })
            : record.role,
      },
      {
        title: <FormattedMessage id="practitioner-roles-form.primary-role-label" />,
        dataIndex: 'isPrimaryRole',
        render: (value, record) => (
          <Checkbox onChange={this.handlePrimaryRole(record, value)} checked={!!value} />
        ),
        align: 'center',
      },
      {
        title: <FormattedMessage id="general.actions" />,
        dataIndex: 'actions',
        width: 100,
        render: (_, record) => this.renderActionButtons(record),
      },
    ];

    const exemption: ColumnType<RoleInCareUnit> = {
      title: <FormattedMessage id="practitioner-roles-form.exemption-label" />,
      dataIndex: 'exemptFromAutomaticHandling',
      render: (_, record) => (
        <Tooltip title={<FormattedMessage id="practitioner-roles-form.exemption-tooltip" />}>
          <Checkbox
            onChange={this.handleRoleExemption(record)}
            checked={!!record.lockedFromAutoManagement}
          />
        </Tooltip>
      ),
      align: 'center',
    };

    if (ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT) {
      mColumns.splice(3, 0, exemption);
    }
    return mColumns;
  }

  @computed
  get rolesSelectOptions() {
    const { rolesStore } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    if (!role) {
      return [];
    } else if (rolesStore.administrativeRoles.includes(role)) {
      return this.props.administrativeRolesOptions;
    } else {
      return this.props.resourceTypesOptions;
    }
  }

  @computed
  get careUnitSelectOptions() {
    const {
      userDataStore: userStore,
      rolesStore,
      careUnitsStore,
      userPermissionsStore,
    } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    // Practitioner can have only one patient receiving role in care unit.
    // Practitioner can have multiple administrative roles in the same CU, but it cannot be the same role.
    if (!role) {
      return [];
    } else if (rolesStore.administrativeRoles.includes(role)) {
      return userPermissionsStore.isSuperAdmin
        ? careUnitsStore.allCareUnitsAsSelectOptions
        : userStore.userCareUnitsAsSelectOptions;
    } else {
      const patientReceivingRoles = [
        ...this.userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions,
      ];

      if (this.activePractitionerRole) {
        const { careUnitId, careUnitName } = this.activePractitionerRole;
        const insertIndex = patientReceivingRoles.findIndex(
          ({ label }) => label.toLowerCase().localeCompare(careUnitName.toLowerCase()) > 0
        );
        patientReceivingRoles.splice(insertIndex, 0, {
          value: careUnitId,
          label: careUnitName,
        });
      }

      return patientReceivingRoles;
    }
  }

  @computed
  get roleBasedCareUnitSelectOptions() {
    return this.context.userDataStore.roleBasedCareUnits.map(careUnit => ({
      value: careUnit.id,
      label: displayCareUnitWithProviderName(careUnit),
    }));
  }

  @computed
  get userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions() {
    const { userDataStore: userStore, practitionerRolesStore } = this.context;

    return userStore.userCareUnitsAsSelectOptions.filter(
      cu => !practitionerRolesStore.patientReceivingRoles.some(role => role.careUnitId === cu.value)
    );
  }

  @computed
  get canAddPatientReceivingRole() {
    const { userPermissionsStore } = this.context;
    return (
      !!this.userCareUnitsWithoutPatientReceivingRoleAssignedAsSelectOptions.length ||
      userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.CLINIC_USER_ADMIN)
    );
  }

  @computed
  get notAvailableCareUnitRoles() {
    // Practitioner can have multiple roles in the same CU, but it cannot be the same role
    const { rolesStore, practitionerRolesStore } = this.context;
    const role = this.newRoleDefinition?.role || this.activePractitionerRole?.role;

    if (!role || !rolesStore.administrativeRoles.includes(role)) {
      return [];
    }

    return practitionerRolesStore.administrativeRoles;
  }

  @action
  handleSetActivePractitionerRole = (role: RoleInCareUnit) => {
    this.activePractitionerRole = role;
  };

  handleRoleExemption = (role: RoleInCareUnit) => async () => {
    const newRole = { ...role, lockedFromAutoManagement: !role.lockedFromAutoManagement };
    this.handleEditPractitionerRole(newRole);
  };

  handlePrimaryRole = (role: RoleInCareUnit, currentValue: boolean) => async () => {
    const newRole = { ...role, isPrimaryRole: !currentValue };
    this.handleEditPractitionerRole(newRole);
  };

  handleRoleDelete = (role: RoleInCareUnit) => async () => {
    const { practitionerRolesStore, userPermissionsStore, careUnitsStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    const partnerId = getPartnerIdByCareUnitIdGivenAllCareUnits(
      careUnitsStore.allCareUnits,
      role.careUnitId
    );

    isClinicUserAdminOrAdmin
      ? await practitionerRolesStore.archivePractitionerExtendedRoleV2(this.props.intl)(
          this.props.match.params.id,
          role.roleId,
          role.careUnitId,
          partnerId
        )
      : await practitionerRolesStore.archivePractitionerExtendedRole(this.props.intl)(
          this.props.match.params.id,
          role.roleId
        );
  };

  @action
  handleClearActivePractitionerRole = () => {
    this.activePractitionerRole = undefined;
  };

  @action
  handleAddNewAdministrativeRole = async () => {
    this.newRoleDefinition = {
      role: DEFAULT_ADMINISTRATIVE_ROLE,
      careUnitIds: [],
    };
    await this.getRoleBasedSelectableCareUnit();
  };

  getRoleBasedSelectableCareUnit = async () => {
    // Check if user has clinic_user_admin scoped role and then fetch care units from the new endpoint
    // else use the old way for showing  care units.
    const { userPermissionsStore, userDataStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    if (isClinicUserAdminOrAdmin) {
      await userDataStore.fetchCareUnitsBasedOnUserRole(ROLE_MENUITEMS.CLINIC_USER_ADMIN);
    }
  };

  @action
  handleAddNewPatientReceivingRole = async () => {
    this.newRoleDefinition = {
      role: DEFAULT_PRACTITIONER_RECEIVING_ROLE,
      careUnitIds: [],
    };
    await this.getRoleBasedSelectableCareUnit();
  };

  @action
  handleClearNewRoleDefinition = () => {
    this.newRoleDefinition = undefined;
  };

  handleAddNewPractitionerRoles = async (practitionerRole: PractitionerRoleDefinition) => {
    const { practitionerRolesStore, userPermissionsStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );
    isClinicUserAdminOrAdmin
      ? await practitionerRolesStore.addNewRoleV2(this.props.intl)(
          this.props.match.params.id,
          practitionerRole
        )
      : await practitionerRolesStore.addNewRole(this.props.intl)(
          this.props.match.params.id,
          practitionerRole
        );
    this.handleClearNewRoleDefinition();
  };

  handleEditPractitionerRole = async (data: RoleInCareUnit) => {
    const { practitionerRolesStore, userPermissionsStore, careUnitsStore } = this.context;
    const isClinicUserAdminOrAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );
    const partnerId = getPartnerIdByCareUnitIdGivenAllCareUnits(
      careUnitsStore.allCareUnits,
      data.careUnitId
    );

    isClinicUserAdminOrAdmin
      ? await practitionerRolesStore.updatePractitionerRoleV2(this.props.intl)(
          this.props.match.params.id,
          data,
          partnerId
        )
      : await practitionerRolesStore.updatePractitionerRole(this.props.intl)(
          this.props.match.params.id,
          data
        );

    this.handleClearActivePractitionerRole();
  };

  renderActionButtons = (listItem: RoleInCareUnit) => {
    const { userDataStore: userStore, userPermissionsStore } = this.context;
    const isDeletableCareUnit =
      userPermissionsStore.isSuperAdmin ||
      !!userStore.userCareUnitsAsSelectOptions.find(
        careUnit => careUnit.value === listItem.careUnitId
      ) ||
      userPermissionsStore.getSideBarAccess(ROLE_MENUITEMS.CLINIC_USER_ADMIN);

    if (!isDeletableCareUnit) {
      return null;
    }

    return (
      <div>
        <Popconfirm
          title={<FormattedMessage id="general.sure-to-delete" />}
          cancelText={<FormattedMessage id="general.cancel" />}
          onConfirm={this.handleRoleDelete(listItem)}
        >
          <Button type="link" icon={<DeleteOutlined />} />
        </Popconfirm>
      </div>
    );
  };

  render() {
    const {
      careUnitsStore,
      practitionerRolesStore,
      practitionerStore,
      rolesStore,
      partnersStore,
      userPermissionsStore,
    } = this.context;
    const isLoading =
      careUnitsStore.isLoading() ||
      practitionerStore.isLoading() ||
      practitionerStore.isSaving() ||
      rolesStore.isLoading();
    const showRoleLock = partnersStore.partnerConfig.get(
      Config.ADMIN_CAN_LOCK_ROLES_FROM_AUTO_MANAGEMENT
    );
    const isClinicUserAdminORSuperAdmin = userPermissionsStore.getSideBarAccess(
      ROLE_MENUITEMS.CLINIC_USER_ADMIN,
      ROLE_MENUITEMS.SUPER_ADMIN
    );

    return (
      <Fragment>
        <div className={styles.roles}>
          <Typography.Title level={3} className={styles.rolesHeader}>
            <FormattedMessage id="practitioner-roles-form.practitioner-roles-header" />
            <Tooltip
              title={
                !this.canAddPatientReceivingRole && (
                  <FormattedMessage id="practitioner-roles-form.no-care-units-available" />
                )
              }
              placement="left"
            >
              <Button
                type="primary"
                shape="round"
                data-testid="add-patient-receiving-role-btn"
                onClick={this.handleAddNewPatientReceivingRole}
                disabled={isLoading || !this.canAddPatientReceivingRole}
              >
                <FormattedMessage id="add-roles-form.add-btn" />
              </Button>
            </Tooltip>
          </Typography.Title>
          <Typography.Paragraph>
            <FormattedMessage id="practitioner-roles-form.practitioner-roles-description" />
          </Typography.Paragraph>
          <Table<RoleInCareUnit>
            data-testid="practitioner-roles-list"
            dataSource={practitionerRolesStore.patientReceivingRoles}
            pagination={false}
            loading={isLoading}
            rowKey="roleId"
            columns={this.columns}
          />
        </div>
        <div className={styles.roles}>
          <Typography.Title level={3} className={styles.rolesHeader}>
            <FormattedMessage id="practitioner-roles-form.administrative-roles-header" />
            <Button
              type="primary"
              shape="round"
              data-testid="add-administrative-role-btn"
              onClick={this.handleAddNewAdministrativeRole}
              disabled={isLoading}
            >
              <FormattedMessage id="add-roles-form.add-btn" />
            </Button>
          </Typography.Title>
          <Typography.Paragraph>
            <FormattedMessage id="practitioner-roles-form.administrative-roles-description" />
          </Typography.Paragraph>
          <Table<RoleInCareUnit>
            data-testid="administrative-roles-list"
            dataSource={practitionerRolesStore.administrativeRoles}
            pagination={false}
            loading={isLoading}
            rowKey="roleId"
            columns={this.columns}
          />
        </div>
        <AddPractitionerRoles
          initialValues={this.newRoleDefinition}
          isSaving={isLoading}
          onCancel={this.handleClearNewRoleDefinition}
          onSubmit={this.handleAddNewPractitionerRoles}
          rolesOptions={this.rolesSelectOptions}
          careUnitsOptions={
            isClinicUserAdminORSuperAdmin
              ? this.roleBasedCareUnitSelectOptions
              : this.careUnitSelectOptions
          }
          notAvailableCareUnitRoles={this.notAvailableCareUnitRoles}
          showRoleLock={showRoleLock}
        />
        <EditPractitionerRole
          role={this.activePractitionerRole}
          isSaving={isLoading}
          onCancel={this.handleClearActivePractitionerRole}
          onSubmit={this.handleEditPractitionerRole}
          rolesOptions={this.rolesSelectOptions}
          careUnitsOptions={
            isClinicUserAdminORSuperAdmin
              ? this.roleBasedCareUnitSelectOptions
              : this.careUnitSelectOptions
          }
          notAvailableCareUnitRoles={this.notAvailableCareUnitRoles}
          showRoleLock={showRoleLock}
        />
      </Fragment>
    );
  }
}

export default withPractitionerRolesOptions(injectIntl(withRouter(PractitionerRoles)));
