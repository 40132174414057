import {
  RotateRightOutlined,
  SaveOutlined,
  UploadOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Button, Input, Modal, Slider, App } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Cropper, { type Area } from 'react-easy-crop';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from 'styled-components';
import { useFilePicker } from 'use-file-picker';
import { FileAmountLimitValidator, FileSizeValidator } from 'use-file-picker/validators';

import { Image } from 'components/Image';
import { DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT } from 'constants/general';
import { useBoolean } from 'hooks/useBoolean';

import { getCroppedImg, getResizedImage, Size } from './imageUtils';
import { MimeTypeValidator } from './MimeTypeValidator';
import { ImageData } from '../../../../../components/RichTextEditor/components/ImageModal';

const Styled = {
  SliderContainer: styled.div`
    position: relative;
    padding: 0 30px;

    .anticon {
      position: absolute;
      top: -2px;
      width: 16px;
      height: 16px;
      color: rgba(0, 0, 0, 0.45);
      font-size: 16px;
      line-height: 1;
    }

    .anticon:first-child {
      left: 0;
    }

    .anticon:last-child {
      right: 0;
    }

    .ant-slider-handle {
      width: 14px;
      height: 14px;
    }
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1em;
    position: relative;
    height: 446px;
    width: 752px;
  `,
};

type UploadImageButtonProps = {
  label: React.ReactNode;
  disabled?: boolean;
  onUpload: (data: Pick<ImageData, 'src' | 'imageName'>) => void;
} & (
  | { allowCropAndResize?: null; targetImageWidth: number }
  | { allowCropAndResize: true; targetImageSize: Size }
);

export const UploadImageButton = (props: UploadImageButtonProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const { notification } = App.useApp();
  const intl = useIntl();
  const [zoom, setZoom] = useState(1);
  const [rotationInDegrees, setRotationInDegrees] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const imageModalOpen = useBoolean(false);
  const [sourceImageFile, setSourceImageFile] = useState<string | null>(null);
  const [imageName, setImageName] = useState<string>('');
  const handleSave = useCallback(async () => {
    if (!sourceImageFile) {
      return;
    }

    try {
      let imageResult;
      /**
       * We are aiming for a 3x size of the target image
       * to avoid pixelation when the image is zoomed in.
       */
      if (props.allowCropAndResize) {
        if (!croppedAreaPixels) {
          return;
        }
        imageResult = await getCroppedImg({
          imageSrc: sourceImageFile,
          pixelCrop: croppedAreaPixels,
          rotation: rotationInDegrees,
          targetImageSize: {
            height: props.targetImageSize.height * 3,
            width: props.targetImageSize.width * 3,
          },
        });
      } else {
        imageResult = await getResizedImage({
          imageSrc: sourceImageFile,
          targetImageWidth: props.targetImageWidth * 3,
        });
      }

      if (!imageResult) {
        return;
      }

      props.onUpload({ src: imageResult, imageName });
      imageModalOpen.setFalse();
    } catch (e) {
      console.error(e);
    }
  }, [sourceImageFile, props, croppedAreaPixels, rotationInDegrees, imageName, imageModalOpen]);

  const handleScalePercentChange = useCallback(
    (newScalePercent: number | number[]) => {
      if (Array.isArray(newScalePercent) || isNaN(newScalePercent)) {
        // Note that this should not happen.
        // It's just a safeguard copied from: https://ant.design/components/slider
        return;
      }
      setZoom(newScalePercent / 100);
    },
    [setZoom]
  );

  const handleRotate = useCallback(() => {
    setRotationInDegrees((rotationInDegrees + 90) % 360);
  }, [setRotationInDegrees, rotationInDegrees]);

  const scaleFormatter = useCallback((value?: number | undefined) => `${value ?? 100}%`, []);

  const { openFilePicker, plainFiles } = useFilePicker({
    accept: 'image/*',
    multiple: false,
    validators: [
      new FileAmountLimitValidator({ max: 1 }),
      new MimeTypeValidator([
        // taken from https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types#common_image_file_types
        'image/apng',
        'image/avif',
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/svg+xml',
        'image/webp',
        // worse options, but still supported
        'image/bmp',
        'image/tiff',
      ]),
      new FileSizeValidator({ maxFileSize: 15 * 1024 * 1024 }), // 15 MB is a lot, but we resize the image before uploading anyway so it becomes ~10 times smaller
    ],
    onFilesRejected: ({ errors }) => {
      // The accept 'image/*' in the file picker can not be entirely trusted.
      // Apparently it can be bypassed on Windows in some cases.
      // Not important from a security aspect but handles errors much cleaner.
      const fileTypeError = errors.find(e => e.name === 'FileTypeError');
      if (fileTypeError) {
        notification.error({
          message: intl.formatMessage({ id: 'images.error.unsupported-format' }),
          placement: 'top',
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        });
        return;
      }
    },
  });

  useEffect(
    function handleImageSelectFromFileSystem() {
      if (plainFiles.length === 0) {
        imageModalOpen.setFalse();
        return;
      }

      const file = plainFiles[0];

      if (!file.type.startsWith('image/')) {
        // The accept 'image/*' in the file picker can not be entirely trusted.
        // Apparently it can be bypassed on Windows in some cases.
        // Not important from a security aspect but handles errors much cleaner.
        notification.error({
          message: intl.formatMessage({ id: 'images.error.unsupported-format' }),
          placement: 'top',
          duration: DEFAULT_ERROR_FLASH_MESSAGE_TIMEOUT,
        });
        return;
      }

      setSourceImageFile(URL.createObjectURL(file));
      setImageName(file.name.split('.').slice(0, -1).join('.'));
      setZoom(1);
      setRotationInDegrees(0);
      imageModalOpen.setTrue();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plainFiles, setSourceImageFile, imageModalOpen.setFalse, imageModalOpen.setTrue]
  );

  const animationEnded = useBoolean(false);
  const handleModalAnimationEnd = useCallback(
    (open: boolean) => {
      animationEnded.setValue(open);
    },
    [animationEnded]
  );

  return (
    <div>
      <Button icon={<UploadOutlined />} disabled={props.disabled} onClick={openFilePicker}>
        <>{props.label}</>
      </Button>

      <Modal
        afterOpenChange={handleModalAnimationEnd}
        footer={[
          <Button key="cancel" onClick={imageModalOpen.setFalse}>
            <>
              <FormattedMessage id="general.cancel" />
            </>
          </Button>,
          props.allowCropAndResize && (
            <Button key="rotate" icon={<RotateRightOutlined />} onClick={handleRotate}>
              <>
                <FormattedMessage id="general.rotate" />
              </>
            </Button>
          ),
          <Button
            key="save"
            disabled={props.disabled}
            icon={<SaveOutlined />}
            type="primary"
            onClick={handleSave}
          >
            <>
              <FormattedMessage id="general.save" />
            </>
          </Button>,
        ]}
        maskClosable={false}
        open={imageModalOpen.value}
        title={<FormattedMessage id="image-modal.upload.modal-title" />}
        width={800}
        onCancel={imageModalOpen.setFalse}
      >
        <label>
          <FormattedMessage id="image-modal.upload.name-label" />
          <Input
            value={imageName}
            onChange={v => setImageName(v.target.value)}
            maxLength={100}
          ></Input>
        </label>

        <Styled.ImageContainer>
          {animationEnded.value ? (
            props.allowCropAndResize ? (
              <Cropper
                aspect={props.targetImageSize.width / props.targetImageSize.height}
                crop={crop}
                image={sourceImageFile || ''}
                rotation={rotationInDegrees}
                showGrid={false}
                zoom={zoom}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onRotationChange={setRotationInDegrees}
                onZoomChange={setZoom}
              />
            ) : (
              <Image src={sourceImageFile || ''} />
            )
          ) : null}
        </Styled.ImageContainer>

        {props.allowCropAndResize && (
          <Styled.SliderContainer>
            <ZoomOutOutlined />
            <Slider
              max={300}
              min={40}
              tooltip={{
                formatter: scaleFormatter,
              }}
              value={zoom * 100}
              onChange={handleScalePercentChange}
            />
            <ZoomInOutlined />
          </Styled.SliderContainer>
        )}
      </Modal>
    </div>
  );
};
